import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton, TextField, Button, Badge } from '@material-ui/core';
import {
  DataGridPro,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarColumnsButton,
  useGridApiRef,
} from '@mui/x-data-grid-pro';

import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import { createTheme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core/styles';

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        fontColor: '#000',
        backgroundColor: '#fff',
      },
      filterText: {
        color: '#000',
      },
      textField: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme },
);

function QuickSearchToolbar(props) {
  const classes = useStyles();
  let addButton = '';
  if (props.addItem) {
    addButton = props.addItem;
  }
  return (
    <div className={classes.root}>
      <div>
        {addButton}
        <GridToolbarColumnsButton className={classes.filterText} />
        <GridToolbarFilterButton
          componentsProps={{
            button: {
              startIcon: (
                <Badge
                  badgeContent={
                    props.filterModel.items.filter((item) => {
                      return item.value && item.value.length > 0;
                    }).length || 0
                  }
                  color="primary"
                  overlap="rectangular"
                >
                  <FilterListIcon />
                </Badge>
              ),
            },
          }}
          className={classes.filterText}
        />
        <GridToolbarDensitySelector className={classes.filterText} />
        <GridToolbarExport
          csvOptions={{ allColumns: true }}
          className={classes.filterText}
        />
        {props.selection && props.selection.length > 0 ? (
          <Button
            color="primary"
            variant="contained"
            onClick={() => props.deleteItems(props.selection)}
          >
            Delete Selected
          </Button>
        ) : (
          ''
        )}
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default function QuickFilteringGrid(props) {
  let data = {
    rows: props.rows,
    rowLength: 100,
    maxColumns: 6,
    columns: props.columns
  };

  const [searchText, setSearchText] = React.useState('');
  const [pageSize, setPageSize] = React.useState(25);
  const [selection, setSelection] = React.useState([]);
  const [rows, setRows] = React.useState(props.rows);
  const [columns, setColumns] = React.useState(data.columns);

  const apiRef = useGridApiRef();

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.rows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  const deleteRows = (reportIds) => {
    setSelection([]);
    let newRows = rows.filter((item) => !reportIds.includes(item.id));
    setRows(newRows);
    if (props.deleteItems) {
      props.deleteItems(reportIds);
    }
  };

  useEffect(() => {
    setSelection([]);
    let newRows = [...props.rows];
    setRows(newRows);
    apiRef.current.updateRows(newRows);
  }, [props.rows]);

  const [filterModel, setFilterModel] = React.useState({
    items: [props.filter],
  });
  let checkboxSelect = false;
  if (props.checkboxSelection) {
    checkboxSelect = true;
  }

  return (
    <div>
      <DataGridPro
        components={{ Toolbar: QuickSearchToolbar }}
        rows={rows}
        columns={columns}
        disableSelectionOnClick={true}
        density={'comfortable'}
        pageSize={pageSize}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        rowsPerPageOptions={[25, 50, 100, 500, 1000]}
        pagination
        filterModel={filterModel}
        onFilterModelChange={(model) => setFilterModel(model)}
        autoHeight={!props.showLoadingRing || true}
        apiRef={apiRef}
        checkboxSelection={checkboxSelect}
        onSelectionModelChange={(newSelection) => {
          setSelection(newSelection);
        }}
        selectionModel={selection}
        disableColumnMenu={false}
        componentsProps={{
          toolbar: {
            filterModel: filterModel,
            value: searchText,
            deleteItems: (reportIds) => deleteRows(reportIds),
            addItem: props.addItem,
            selection: selection,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(''),
          },
        }}
      />
    </div>
  );
}
