import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { XGrid, GridToolbar } from '@material-ui/x-grid';
import {
  Dialog,
  Button,
  IconButton,
  Grid,
  Card,
  CardContent,
  Typography,
  InputLabel,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

// import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Store from '../services/Store';
import QuickFilteringGrid from '../components/QuickFilteringGrid';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ErrorIcon from '@material-ui/icons/Error';
import FormControl from '@material-ui/core/FormControl';

import withStyles from '@material-ui/core/styles/withStyles';
import axios from '../helpers/axios';
import PhotoModal from '../components/PhotoModal';
import ConfirmDialog from '../components/ConfirmDialog';
import Assign from './reports/Assign';
import Resizer from 'react-image-file-resizer';
import Tooltip from '@material-ui/core/Tooltip';
import piexif from 'piexifjs';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

function countdown(s) {
  const d = Math.floor(s / (3600 * 24));

  s -= d * 3600 * 24;

  const h = Math.floor(s / 3600);

  s -= h * 3600;

  const m = Math.floor(s / 60);

  s -= m * 60;

  const tmp = [];

  d && tmp.push(d + 'd');

  (d || h) && tmp.push(h + 'h');

  (d || h || m) && tmp.push(m + 'm');

  return tmp.join(' ');
}
function addZero(i) {
  if (i < 10) {
    i = '0' + i;
  }
  return i;
}
function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = addZero(a.getHours());
  var ampm = hour >= 12 ? 'pm' : 'am';
  hour = hour % 12;
  hour = hour ? hour : 12; // the hour '0' should be '12'
  var min = addZero(a.getMinutes());
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ampm;
  return time;
}
function arrayUnique(array) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
}
const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
  },
  saveRight: {
    marginLeft: 'auto',
    marginTop: '10px',
    float: 'right',
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: '15px',
  },
  buttonProperty: {
    position: 'absolute',
    top: '50%',
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    marginLeft: -75,
    top: '35%',
  },
  progess: {
    position: 'absolute',
  },
  uploadButton: {
    marginLeft: '8px',
    margin: theme.spacing(1),
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10,
  },
  submitButton: {
    marginTop: '10px',
  },
});

// confirmationFunction: () => {console.log('confirm delete');this.resolveReport(params.id, params.getValue(params.id, 'qrCodeID'))},

class QrCodes extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    var sevenDaysAgo = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);
    var dd7 = sevenDaysAgo.getDate();
    var mm7 = sevenDaysAgo.getMonth() + 1;
    var yyyy7 = sevenDaysAgo.getFullYear();
    if (dd7 < 10) {
      dd7 = '0' + dd7;
    }

    if (mm7 < 10) {
      mm7 = '0' + mm7;
    }
    this.state = {
      reports: [],
      uiLoading: true,
      resolutionUpdating: false,
      buttonLoading: false,
      searchValue: '',
      confirmOpen: false,
      confirmModalTitle: '',
      photoPreviewAfter: 'Attach Photo',
      filterStart: yyyy7 + '-' + mm7 + '-' + dd7,
      filterEnd: yyyy + '-' + mm + '-' + dd,
      filterStatus: 'any',
      filterUser: 'Any',
      filterRubbishType: 'Any',
      teamMembers: ['Any'],
      rubbishTypes: [],
      schemeTypes: [],
      rubbishTypesFilter: [],
      rubbishTypesFilterLoading: false,
      teamMembersLoading: false,
    };
    this.columns = [
      {
        field: 'id',
        headerName: 'IDS',
        hide: true,
      },
      {
        field: 'image',
        headerName: 'Report',
        hideSortIcons: true,
        sortable: false,
        valueFormatter: (params) => {
          if (
            params.getValue(params.id, 'rubbishPhotoURL') &&
            params.getValue(params.id, 'rubbishPhotoURL').length > 0
          ) {
            return 'True';
          } else {
            return 'False';
          }
        },
        renderCell: (params) => {
          if (
            params.getValue(params.id, 'rubbishPhotoURL') &&
            params.getValue(params.id, 'rubbishPhotoURL').length > 0
          ) {
            return (
              <PhotoModal
                rubbishPhotoURL={params.getValue(params.id, 'rubbishPhotoURL')}
              />
            );
            // return <img onClick={() => alert('clicked')} style={{height: '100px'}} src={params.getValue(params.id, 'rubbishPhotoURL')} />;
          } else {
            return '';
          }
        },
      },
      {
        field: 'resImage',
        headerName: 'Resolution',
        hideSortIcons: true,
        sortable: false,
        minWidth: 120,
        valueFormatter: (params) => {
          if (
            params.getValue(params.id, 'rubbishAfterPhotoURL') &&
            params.getValue(params.id, 'rubbishAfterPhotoURL').length > 0
          ) {
            return 'True';
          } else {
            return 'False';
          }
        },
        renderCell: (params) => {
          if (
            params.getValue(params.id, 'rubbishAfterPhotoURL') &&
            params.getValue(params.id, 'rubbishAfterPhotoURL').length > 0
          ) {
            return (
              <PhotoModal
                rubbishPhotoURL={params.getValue(
                  params.id,
                  'rubbishAfterPhotoURL',
                )}
              />
            );
            // return <img style={{height: '100px'}} src={params.getValue(params.id, 'rubbishAfterPhotoURL')} />;
          } else {
            return '';
          }
        },
      },
      {
        field: 'qrCodeID',
        headerName: 'Qr Code ID',
        hide: true,
        hideSortIcons: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'reportedFrom',
        minWidth: 170,
        headerName: 'Reported From',
        valueGetter: (params) => {
          let identifier = params.getValue(params.id, 'identifier');
          let codeID = params.getValue(params.id, 'qrCodeID');
          if (codeID == 'NONE') {
            return 'Mobile User';
          } else if (identifier) {
            return 'Qr Code: ' + identifier;
          } else {
            return 'Identifier Removed';
          }
        },
        valueFormatter: (params) => {
          let identifier = params.getValue(params.id, 'identifier');
          let codeID = params.getValue(params.id, 'qrCodeID');
          if (codeID == 'NONE') {
            return 'Mobile User';
          } else if (identifier) {
            return identifier;
          } else {
            return 'Identifier Removed';
          }
        },
        renderCell: (params) => {
          let identifier = params.getValue(params.id, 'identifier');
          let codeID = params.getValue(params.id, 'qrCodeID');
          if (codeID == 'NONE') {
            return 'Mobile User';
          } else if (identifier) {
            return 'Qr Code: ' + identifier;
          } else {
            return 'Removed';
          }
        },
      },
      {
        field: 'status',
        headerName: 'Status',
      },
      {
        field: 'reportedAt',
        headerName: 'Reported At',
        sort: 'desc',
        minWidth: 200,
        valueGetter: (params) => {
          return params.getValue(params.id, 'reportedTimeStamp');
        },
        valueFormatter: (params) => {
          let reported = params.getValue(params.id, 'reportedTimeStamp');
          return timeConverter(reported);
        },
        renderCell: (params) => {
          let reported = params.getValue(params.id, 'reportedTimeStamp');
          return timeConverter(reported);
        },
      },
      {
        field: 'rubbishType',
        headerName: 'Issue Type',
        minWidth: 150,
      },
      {
        field: 'totalNumberOfItemsTagged',
        headerName: 'QTY',
        filterable: false,
        minWidth: 50,
      },
      {
        field: 'rubbishDescription',
        headerName: 'User Description',
        minWidth: 200,
        flex: 1,
        valueFormatter: (params) => {
          let rubbishDescription = params.getValue(
            params.id,
            'rubbishDescription',
          );
          if (rubbishDescription) {
            return rubbishDescription.replace(/(\r\n|\n|\r)/gm, '');
          } else {
            return '';
          }
        },
        renderCell: (params) => {
          let rubbishDescription = params.getValue(
            params.id,
            'rubbishDescription',
          );
          if (rubbishDescription) {
            return (
              <div
                style={{
                  maxWidth: 200,
                  whiteSpace: 'initial',
                  lineHeight: 1.43,
                }}
              >
                {rubbishDescription.replace(/(\r\n|\n|\r)/gm, '')}
              </div>
            );
          } else {
            return '';
          }
        },
      },
      {
        field: 'userTime',
        headerName: 'User Time',
        minWidth: 150,
        hideSortIcons: true,
        hide: true,
        sortable: false,
        filterable: false,
        valueGetter: (params) => {
          return params.getValue(params.id, 'userTimeStamp');
        },
        valueFormatter: (params) => {
          let reported = params.getValue(params.id, 'userTimeStamp');
          return timeConverter(reported);
        },
        renderCell: (params) => {
          let reported = params.getValue(params.id, 'userTimeStamp');
          return timeConverter(reported);
        },
      },
      {
        field: 'userIpAddress',
        headerName: 'User IP',
        minWidth: 150,
        hide: true,
        hideSortIcons: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'userName',
        headerName: 'User Name',
        minWidth: 150,
        hide: true,
        hideSortIcons: true,
        sortable: true,
        filterable: true,
      },
      {
        field: 'location',
        headerName: 'Location',
        minWidth: 120,
        valueFormatter: (params) => {
          let lat = params.getValue(params.id, 'lat');
          let long = params.getValue(params.id, 'long');
          let location = params.getValue(params.id, 'location');
          if (!location || location.length === 0) {
            return 'http://www.google.com/maps/place/' + lat + ',' + long;
          } else {
            return location;
          }
        },
        renderCell: (params) => {
          let lat = params.getValue(params.id, 'lat');
          let long = params.getValue(params.id, 'long');
          let location = params.getValue(params.id, 'location');
          if (lat && long) {
            return (
              <a
                target="_blank"
                href={'http://www.google.com/maps/place/' + lat + ',' + long}
              >
                {location}
              </a>
            );
          }
        },
      },
      {
        field: 'lat',
        headerName: 'Lat',
        hide: true,
      },
      {
        field: 'long',
        headerName: 'Long',
        hide: true,
      },
      {
        field: 'resolutionTimeDelta',
        headerName: 'Resolution Time',
        minWidth: 155,
        valueFormatter: (params) => {
          let reported = params.getValue(params.id, 'reportedTimeStamp');

          let resolved = params.getValue(params.id, 'resolvedTimeStamp');
          let resolvedAt = params.getValue(params.id, 'resolvedAt');
          if (reported && resolved) {
            return countdown(resolved - reported);
          } else if (reported && resolvedAt) {
            return countdown(resolvedAt - reported);
          }
        },
        renderCell: (params) => {
          let reported = params.getValue(params.id, 'reportedTimeStamp');

          let resolved = params.getValue(params.id, 'resolvedTimeStamp');
          let resolvedAt = params.getValue(params.id, 'resolvedAt');
          if (reported && resolved) {
            return countdown(resolved - reported);
          } else if (reported && resolvedAt) {
            return countdown(resolvedAt - reported);
          }
        },
      },
      {
        field: 'isResolved',
        headerName: 'Is Resolved?',
        type: 'boolean',
        hide: true,
      },
      {
        field: 'resolvedAtTs',
        headerName: 'Resolved At',
        sort: 'desc',
        minWidth: 200,
        valueGetter: (params) => {
          let reported = params.getValue(params.id, 'resolvedTimeStamp');
          let resolvedAt = params.getValue(params.id, 'resolvedAt');
          if (reported) {
            return reported;
          } else if (resolvedAt) {
            return resolvedAt;
          }
        },
        valueFormatter: (params) => {
          let reported = params.getValue(params.id, 'resolvedTimeStamp');
          let resolvedAt = params.getValue(params.id, 'resolvedAt');
          if (reported) {
            return timeConverter(reported);
          } else if (resolvedAt) {
            return timeConverter(resolvedAt);
          }
        },
        renderCell: (params) => {
          let reported = params.getValue(params.id, 'resolvedTimeStamp');
          let resolvedAt = params.getValue(params.id, 'resolvedAt');
          if (reported) {
            return timeConverter(reported);
          } else if (resolvedAt) {
            return timeConverter(resolvedAt);
          }
        },
      },
      {
        field: 'resolution',
        headerName: 'Resolve/Reopen',
        hideSortIcons: true,
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        sortable: false,
        minWidth: 140,
        renderCell: (params) => {
          if (this.state.resolutionUpdating) {
            console.log('updating resolution')
            return ''
          }
          let isResolved = params.getValue(params.id, 'isResolved');
          if (isResolved) {
            return (
              <Tooltip title="Reopen">
                <IconButton
                  onClick={() => {
                    this.setState({
                      confirmationFunction: () => {
                        this.removeResolution(
                          params.id,
                          params.getValue(params.id, 'qrCodeID'),
                        );
                      },
                      confirmModalTitle: 'Reopen this Report?',
                      confirmOpen: true,
                    });
                  }}
                >
                  <CheckCircleRoundedIcon color="success" />
                </IconButton>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title="Resolve">
                <IconButton
                  onClick={() => {
                    this.setState({
                      confirmationFunction: () => {
                        this.resolveReport(
                          params.id,
                          params.getValue(params.id, 'qrCodeID'),
                        );
                      },
                      confirmModalTitle: 'Mark Report as Resolved?',
                      confirmOpen: true,
                    });
                  }}
                >
                  <DoneOutlineIcon />
                </IconButton>
              </Tooltip>
            );
          }
        },
      },
      {
        field: 'assignTo',
        headerName: 'Assign To',
        hideSortIcons: true,
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        sortable: false,
        minWidth: 40,
        valueFormatter: (params) => {},
        renderCell: (params) => {
          return <Assign reportID={params.id} />;
        },
      },
      {
        field: 'notifiedCount',
        headerName: 'Subscribers Notified',
        hide: true,
      },
      {
        field: 'reporterEmail',
        headerName: 'Reporter Email',
        hide: true,
      },
      {
        field: 'phoneNumber',
        headerName: 'Reporter Phone',
        hide: true,
      },
      {
        field: 'subscribeToNewsletter',
        headerName: 'Subscribe to Newsletter',
        hide: true,
        type: 'boolean',
      },
    ];
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  onSelectType = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  setReports = (team, authToken) => {
    const {
      filterStart,
      filterEnd,
      filterStatus,
      filterUser,
      filterRubbishType,
    } = this.state;
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get(`/api/team/${team}/reports`, {
        params: {
          filterStatus: filterStatus,
          filterStart: filterStart,
          filterEnd: filterEnd,
          filterUser: filterUser,
          filterRubbishType: filterRubbishType,
        },
      })
      .then((response) => {
        this.setState({
          reports: response.data,
          showQuickLoad: false,
          uiLoading: false,
        });
        console.log(this.state.reports);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          showQuickLoad: false,
          errorMsg: 'Error in retrieving the data',
        });
      });
  };
  setRubbishTypeFilter = (newTypes) => {
    const { rubbishTypesFilter } = this.state;
    let updateTypes = arrayUnique(rubbishTypesFilter.concat(newTypes));
    this.setState({
      rubbishTypesFilter: updateTypes,
      rubbishTypesFilterLoading: false,
    });
  };
  deleteReports = (reportIds) => {
    const { reports } = this.state;
    let originalReports = reports;
    let newReports = reports.filter((item) => !reportIds.includes(item.id));
    this.setState({ reports: newReports });

    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const team = store.get('currentTeam');
    if (reportIds.length > 0) {
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      let formRequest = {
        reportIds: reportIds,
      };
      axios
        .post(`/api/team/${team}/reports`, formRequest)
        .then((teamResponse) => {})
        .catch((error) => {
          console.log(error);
          this.setState({ reports: originalReports });
        });
    }
  };
  componentWillMount = () => {
    this._isMounted = true;
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    this.setTeam(currentTeam, store.get('user'), authToken);
    this.setReports(currentTeam, authToken);
    store.on('currentTeam').subscribe((currentTeam) => {
      if (this._isMounted) {
        this.setState({ uiLoading: true });
        this.setTeam(currentTeam, store.get('user'), authToken);
        this.setReports(currentTeam, authToken);
      }
    });
  };
  componentWillUnmount() {
    if (this._isMounted) this._isMounted = false;
  }
  setTeam = (team, user, authToken) => {
    if (user.moderator.includes(team)) {
      this.setState({ isModerator: true });
    } else {
      this.setState({ isModerator: false });
    }
    this.setState({
      rubbishTypesFilterLoading: true,
      teamMembersLoading: true,
    });
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get(`/api/team/${team}`)
      .then((response) => {
        this.setState({
          teamMembers: response.data.teamMembers || ['Any'],
          rubbishTypes: response.data.rubbishTypes || [],
          schemeTypes: response.data.schemeTypes || [],
          rubbishTeamTypeSchemeID: response.data.rubbishTeamTypeSchemeID,
          rubbishQrTypesID: response.data.rubbishQrTypesID,
          rubbishTypesFilter: ['Any'],
          teamMembersLoading: false,
          uiLoading: false,
        });
        let filterRubbishType = [];
        if (response.data.schemeTypes) {
          Object.keys(response.data.schemeTypes).forEach((key) => {
            if (response.data.schemeTypes[key].rubbishTypeIDs.count > 0) {
              response.data.schemeTypes[key].rubbishTypeIDs.forEach((type) => {
                if (this.state.rubbishTypes[key]) {
                  filterRubbishType.push(this.state.rubbishTypes[key].title);
                }
              });
            }
          });
        }
        if (Object.keys(this.state.rubbishTypes).length > 0) {
          Object.keys(this.state.rubbishTypes).forEach((key) => {
            if (this.state.rubbishTypes[key].count > 0) {
              filterRubbishType.push(this.state.rubbishTypes[key].title);
            }
          });
        }
        this.setRubbishTypeFilter(filterRubbishType);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ errorMsg: 'Error in retrieving the data' });
      });
  };
  setConfirmOpen = (setConfirm = false) => {
    this.setState({
      confirmOpen: setConfirm,
      confirmModalTitle: '',
      confirmationFunction: undefined,
    });
  };
  triggerPhotoAfter = (e) => {
    e.preventDefault();
    document.getElementById('rubbishAfterPhoto').click();
  };
  showPhotoPreviewAfter = (e) => {
    const { photoPreviewAfter } = this.state;
    const file = document.querySelector('#rubbishAfterPhoto').files[0];
    if (file) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          800,
          800,
          'JPEG',
          100,
          0,
          (uri) => {
            let photoPreviewAfter = <img className="photo-preview" src={uri} />;
            this.setState({ photoPreviewAfter: photoPreviewAfter });
          },
          'base64',
          200,
          200,
        );
        Resizer.imageFileResizer(
          e.target.files[0],
          800,
          800,
          'JPEG',
          100,
          0,
          (uri) => {
            var reader = new FileReader();
            // reader.setState = this.setState;
            //read exif from original file, and apply to newly resized file
            try {
              reader.readAsDataURL(e.target.files[0]);
              reader.onload = () => {
                var reader2 = new FileReader();
                reader2.readAsDataURL(uri);
                reader2.onload = () => {
                  try {
                    var exifObj = piexif.load(reader.result);
                    if (exifObj['0th'][274] === 6) {
                      //idk but this fixes the wierd rotations, 6 tells the computer to rotate it, 0 says dont
                      exifObj['0th'][274] = 0;
                    }
                    var exifStr = piexif.dump(exifObj);

                    let newFile = piexif.insert(exifStr, reader2.result);
                    var exifObj2 = piexif.load(newFile);
                    this.setState({
                      photoStringAfter: dataURLtoFile(newFile, 'image-upload'),
                    });
                  } catch (err) {
                    console.log(err);
                    this.setState({
                      photoStringAfter: uri,
                    });
                  }
                };
                reader2.onerror = function (error) {
                  console.log('Error: ', error);
                };
              };
              reader.onerror = function (error) {
                console.log('Error: ', error);
              };
            } catch (err) {
              console.log(err);
              this.setState({
                photoStringAfter: uri,
              });
            }
          },
          'file',
          200,
          200,
        );
      } catch (err) {
        console.log(err);
        this.setState({
          photoPreviewAfter: 'Error Attaching Photo',
          photoStringAfter: null,
        });
      }
    }
  };
  resolveReport = (reportId, codeID) => {
    const { store } = this.props;
    const { reports } = this.state;

    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    axios.defaults.headers.common = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${authToken}`,
    };

    window.this = this;
    var formData = new FormData();

    formData.append('reportIDs', [reportId]);

    if (this.state.photoStringAfter) {
      formData.append('rubbishAfterPhoto', this.state.photoStringAfter);
    }
    // this.setState({ showQuickLoad: true });
    // console.log('submit');
    axios
      .post(`/api/code/${codeID}/resolve_reports`, formData)
      .then(
        function (teamResponse) {
          let index = reports.findIndex((obj) => obj.id == reportId);
          let allReports = [...reports];
          let newReport = allReports[index];
          allReports[index] = {};
          this.setState({ reports: allReports });

          newReport['isResolved'] = true;
          newReport['status'] = 'Resolved';

          allReports[index] = newReport;
          // this.setState({ reports: allReports });
          this.setState({reports: allReports});
      }.bind(this),
      )
      .catch((error) => {
        console.log(error);
        this.setState({
          buttonLoading: false,
        });
      });
  };
  removeResolution = (reportId, codeID) => {
    const { store } = this.props;
    const { reports } = this.state;

    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    const formRequest = {
      reportIds: [reportId],
    };
    window.this = this;
    // this.setState({ showQuickLoad: true });
    axios
      .post(`/api/code/${codeID}/report_reports`, formRequest)
      .then(function (teamResponse) {
          let index = reports.findIndex((obj) => obj.id == reportId);
          let newReports = [...reports];

          newReports[index]['isResolved'] = false;
          newReports[index]['status'] = 'Reported';
          this.setState({ reports: newReports});
        }.bind(this),
      )
      .catch((error) => {
        console.log(error);
        this.setState({
          buttonLoading: false,
        });
      });
  };
  filterResults = () => {
    const { store } = this.props;
    this.setState({ showQuickLoad: true });
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    this.setReports(currentTeam, authToken);
  };
  render() {
    const { classes } = this.props;
    const { reports, searchValue, teamMembers, rubbishTypesFilter } =
      this.state;

    if (this.state.uiLoading === true) {
      return (
        <div className={classes.root}>
          <Header></Header>

          <main className={classes.content}>
            <div className={classes.toolbar} />
            {this.state.uiLoading && (
              <CircularProgress size={150} className={classes.uiProgess} />
            )}
          </main>
        </div>
      );
    } else {
      let quickLoad = '';
      if (this.state.showQuickLoad) {
        quickLoad = (
          <>
            {this.state.showQuickLoad && (
              <CircularProgress size={15} className={classes.uiProgess} />
            )}
          </>
        );
      }

      let quickFilter = (
        <>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h5">
                  Filter
                </Typography>
                <Grid container spacing={1}>
                  <Grid item sm={2} xs={6}>
                    <InputLabel id="startDateRubbishTypeLabel">
                      Start Date
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="startDate"
                      type="date"
                      name="filterStart"
                      defaultValue={this.state.filterStart}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item sm={2} xs={6}>
                    <InputLabel id="endDateRubbishTypeLabel">
                      End Date
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="endDate"
                      type="date"
                      name="filterEnd"
                      defaultValue={this.state.filterEnd}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item sm={2} xs={12}>
                    <InputLabel id="newSelectRubbishTypeLabel">
                      Status
                    </InputLabel>
                    <Select
                      fullWidth
                      value={this.state.filterStatus}
                      name="filterStatus"
                      label="Status"
                      id="filterStatus"
                      onChange={this.onSelectType}
                    >
                      <MenuItem key={'any'} value={'any'}>
                        Any
                      </MenuItem>
                      <MenuItem key={'resolved'} value={'Resolved'}>
                        Resolved
                      </MenuItem>
                      <MenuItem key={'reported'} value={'Reported'}>
                        Reported
                      </MenuItem>
                      <MenuItem key={'logged'} value={'Logged'}>
                        Logged
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <InputLabel id="newSelectRubbishTypeLabel">User</InputLabel>
                    <Select
                      fullWidth
                      value={this.state.filterUser}
                      name="filterUser"
                      label="User"
                      id="filterUser"
                      onChange={this.onSelectType}
                    >
                      <MenuItem key={'any'} value={'Any'}>
                        Any
                      </MenuItem>
                      {this.state.teamMembersLoading ? (
                        <MenuItem key={'loading'} value={'Any'}>
                          Loading...
                        </MenuItem>
                      ) : (
                        teamMembers.map((teamMember) => {
                          const s =
                            teamMember['firstName'] +
                            ' ' +
                            teamMember['lastName'];
                          return (
                            <MenuItem
                              key={teamMember['userID']}
                              value={teamMember['userID']}
                            >
                              {s}
                            </MenuItem>
                          );
                        })
                      )}
                    </Select>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <InputLabel id="newSelectRubbishTypeLabel">
                      Issue Type
                    </InputLabel>
                    <Select
                      fullWidth
                      value={this.state.filterRubbishType}
                      name="filterRubbishType"
                      label="Issue Type"
                      id="filterRubbishType"
                      onChange={this.onSelectType}
                    >
                      {this.state.rubbishTypesFilterLoading ? (
                        <MenuItem key={'loading'} value={'Any'}>
                          Loading...
                        </MenuItem>
                      ) : rubbishTypesFilter.length > 0 ? (
                        rubbishTypesFilter.map((key) => {
                          return (
                            <MenuItem key={key} value={key}>
                              {key}
                            </MenuItem>
                          );
                        })
                      ) : (
                        ''
                      )}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className={classes.saveRight}
                      color="primary"
                      variant="contained"
                      type="submit"
                      onClick={this.filterResults}
                    >
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </>
      );
      return (
        <div className={classes.root}>
          <Header></Header>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {quickFilter}
              </Grid>
              <Grid item xs={12}>
                <QuickFilteringGrid
                  showLoadingRing={this.state.showQuickLoad}
                  checkboxSelection={true}
                  quickFilter={true}
                  deleteItems={this.deleteReports}
                  filter={{
                    id: 1,
                    columnField: 'rubbishType',
                    operatorValue: 'isNotEmpty',
                  }}
                  rows={this.state.reports}
                  columns={this.columns}
                />
              </Grid>
            </Grid>

            <Footer></Footer>
          </main>
          <ConfirmDialog
            title={this.state.confirmModalTitle}
            open={this.state.confirmOpen}
            setOpen={this.setConfirmOpen}
            onConfirm={() => this.state.confirmationFunction()}
          >
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <Button
                className={classes.button}
                variant="outlined"
                fullWidth
                size="large"
                endIcon={<CameraAltIcon />}
                onClick={this.triggerPhotoAfter}
              >
                {this.state.photoPreviewAfter}
              </Button>
              <input
                onChange={this.showPhotoPreviewAfter}
                id="rubbishAfterPhoto"
                name="rubbishAfterPhoto"
                type="file"
                accept="image/*"
                capture="camera"
              />
            </FormControl>
          </ConfirmDialog>
        </div>
      );
    }
  }
}

export default Store.withStore(withStyles(styles)(QrCodes));
