import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  List,
  Link,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { sendSignInLink } from '../helpers/auth';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Store from '../services/Store';
import withStyles from '@material-ui/core/styles/withStyles';
// import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from '../helpers/axios';
import clsx from 'clsx';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import MailIcon from '@material-ui/icons/Mail';

import Modal from '@material-ui/core/Modal';
import Badge from '@material-ui/core/Badge';
import Snackbar from '@material-ui/core/Snackbar';

import AddTeamMember from './teams/AddTeamMember';
import EditTeamMember from './teams/EditTeamMember';
import ManageInvites from './teams/ManageInvites';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import BackspaceIcon from '@material-ui/icons/Backspace';
import Tooltip from '@material-ui/core/Tooltip';
import SelectTimezoneMaterialUi from 'select-timezone-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={'simple-tabpanel-${index}'}
      aria-labelledby={'simple-tab-${index}'}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: 0 }} p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  appBar: {
    backgroundColor: '#AAA',
  },
  toolbar: theme.mixins.toolbar,
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  },
  details: {
    display: 'flex',
  },
  badge: {
    marginLeft: '30px',
    marginRight: '30px',
  },
  gridContainer: {
    flexGrow: 1,
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: '15px',
  },
  buttonProperty: {
    position: 'absolute',
    top: '50%',
  },
  selectControl: {
    width: '100%',
    textAlign: 'left',
  },
  button: {
    textTransform: 'none',
  },
  formControl: {
    width: '100%',
    padding: theme.spacing(1),
  },

  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    marginLeft: -75,
    top: '35%',
  },
  progess: {
    position: 'absolute',
  },
  uploadButton: {
    marginLeft: '8px',
    margin: theme.spacing(1),
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10,
  },
  saveRight: {
    marginLeft: 'auto',
    marginTop: '10px',
  },
  submitButton: {
    marginTop: '10px',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

class Team extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      uiLoading: true,
      buttonLoading: false,
      teamDisplayName: '',
      teamSlugID: '',
      createNewType: false,
      confirmationTextMessageText: '',
      confirmationEmailMessageText: '',
      resolutionTextMessageText: '',
      resolutionEmailMessageText: '',
      team: { teamMembers: [], membersID: [] },
      footerPhotoLink: '',
      newsletterText: '',
      schemeTypes: [],
      rubbishTeamTypeSchemeID: '',
      modalOpen: false,
      vertical: 'top',
      horizontal: 'right',
      toastOpen: false,
      selectIssueOverride: 'Select issue type...',
      submitButtonOverride: 'Submit',
      reportAnotherOverride: '',
      reportFirstOverride: '',
      defaultTimezone: '',
      timezoneOffset: '',
      photoPreview: 'Upload Team Logo (150px x 25px)',
      toastMessage: '',
      tabValue: 0,
      modalTypeOpen: false,
      teamEditOpen: false,
      newSelectRubbishType: '',
      isModerator: false,
    };
  }
  clearState = () => {
    this.setState({
      teamDisplayName: '',
      teamSlugID: '',
    });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  async sendSignInLinkToEmail(email) {
    await sendSignInLink(email)
      .then(() => {
        this.setToast('Login Link Sent');
      })
      .catch((error) => {
        this.setToast('Error Sending Login Link');
      });
  }
  handleCheck = (event) => {
    if (event.target.checked) {
      this.setState({ [event.target.name]: true });
    } else {
      this.setState({ [event.target.name]: false });
    }
  };

  setTeam = (team, notUser, authToken) => {
    const { store } = this.props;
    let user = store.get('user');
    if (user.moderator.includes(team)) {
      this.setState({ isModerator: true });
    } else {
      this.setState({ isModerator: false });
    }
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get(`/api/team/${team}`)
      .then((response) => {
        this.setState({
          team: {
            teamID: response.data.teamID,
            footerPhotoURL: response.data.footerPhotoURL,
            teamMembers: response.data.teamMembers || [],
            membersID: response.data.membersID || [],
            moderatorsID: response.data.moderatorsID || [],
            ownersID: response.data.ownersID || [],
          },
          newsletterText: response.data.newsletterText,
          footerPhotoLink: response.data.footerPhotoLink,
          confirmationEmailMessageText:
            response.data.confirmationEmailMessageText,
          confirmationTextMessageText:
            response.data.confirmationTextMessageText,
          resolutionEmailMessageText: response.data.resolutionEmailMessageText,
          resolutionTextMessageText: response.data.resolutionTextMessageText,
          defaultTimezone: response.data.defaultTimezone || undefined,
          timezoneOffset: response.data.timezoneOffset || '',
          pageHeader: response.data.pageHeader || 'Report an Issue',
          pageSubHeader:
            response.data.pageSubHeader ||
            'The submission will be shared with the team',
          successTitle:
            response.data.successTitle || 'Issue Reported Succesfully',
          successSubtitle:
            response.data.successSubtitle ||
            'Thank you for your submission. Leave your info below to get a message when the issue is resolved.',
          finalTitle: response.data.finalTitle || 'Issue Reported Succesfully',
          finalSubtitle:
            response.data.finalSubtitle ||
            "Great! We'll send you a message when the issue has been resolved.",
          skipNotificationRequest:
            response.data.skipNotificationRequest || false,
          enableEmailNotifications:
            response.data.enableEmailNotifications || false,
          enableTextNotifications:
            response.data.enableTextNotifications || false,
          enableAICategorization:
            response.data.enableAICategorization || false,
          rubbishTypes: response.data.rubbishTypes || [],
          schemeTypes: response.data.schemeTypes || [],
          rubbishTeamTypeSchemeID: response.data.rubbishTeamTypeSchemeID,
          rubbishQrTypesID: response.data.rubbishQrTypesID,
          teamDisplayName: response.data.teamDisplayName,
          teamSlugID: response.data.teamSlugID,
          selectIssueOverride:
            response.data.selectIssueOverride || 'Select issue type...',
          submitButtonOverride: response.data.submitButtonOverride || 'Submit',
          reportAnotherOverride: response.data.reportAnotherOverride || '',
          reportFirstOverride: response.data.reportFirstOverride || '',
          uiLoading: false,
        });

        // if (response.data.footerPhotoURL) {
        //   this.ssetState({
        //     photoPreview: (<img src={response.data.footerPhotoURL})
        //   });
        // }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ errorMsg: 'Error in retrieving the data' });
      });
  };
  componentDidMount = () => {
    this._isMounted = true;
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    this.setTeam(currentTeam, store.get('user'), authToken);
    store.on('currentTeam').subscribe((currentTeam) => {
      if (this._isMounted) {
        this.clearState();
        this.setState({ uiLoading: true });
        this.setTeam(currentTeam, store.get('user'), authToken);
      }
    });
  };
  componentWillUnmount() {
    if (this._isMounted) this._isMounted = false;
  }
  updateTeam = () => {
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    var formData = new FormData();
    let userAgent = navigator.userAgent ? navigator.userAgent : '';

    formData.append('teamDisplayName', this.state.teamDisplayName);
    formData.append(
      'rubbishTeamTypeSchemeID',
      this.state.rubbishTeamTypeSchemeID,
    );
    formData.append('rubbishQrTypesID', this.state.rubbishQrTypesID);
    formData.append('teamSlugID', this.state.teamSlugID);
    formData.append('defaultTimezone', this.state.defaultTimezone);
    formData.append('timezoneOffset', this.state.timezoneOffset);
    formData.append('footerPhotoLink', this.state.footerPhotoLink);
    formData.append('newsletterText', this.state.newsletterText);
    formData.append(
      'confirmationEmailMessageText',
      this.state.confirmationEmailMessageText,
    );
    formData.append(
      'confirmationTextMessageText',
      this.state.confirmationTextMessageText,
    );
    formData.append(
      'resolutionEmailMessageText',
      this.state.resolutionEmailMessageText,
    );
    formData.append(
      'resolutionTextMessageText',
      this.state.resolutionTextMessageText,
    );
    formData.append('pageHeader', this.state.pageHeader);
    formData.append('pageSubHeader', this.state.pageSubHeader);

    formData.append('successTitle', this.state.successTitle);
    formData.append('successSubtitle', this.state.successSubtitle);
    formData.append('finalTitle', this.state.finalTitle);
    formData.append('finalSubtitle', this.state.finalSubtitle);
    formData.append('selectIssueOverride', this.state.selectIssueOverride);
    formData.append('submitButtonOverride', this.state.submitButtonOverride);
    formData.append('reportAnotherOverride', this.state.reportAnotherOverride);
    formData.append('reportFirstOverride', this.state.reportFirstOverride);
    formData.append(
      'skipNotificationRequest',
      this.state.skipNotificationRequest,
    );
    formData.append(
      'enableEmailNotifications',
      this.state.enableEmailNotifications,
    );
    formData.append(
      'enableTextNotifications',
      this.state.enableTextNotifications,
    );
    formData.append(
      'enableAICategorization',
      this.state.enableAICategorization,
    );
    if (document.querySelector('#rubbishPhoto')) {
      var file = document.querySelector('#rubbishPhoto').files[0];
      if (file) {
        formData.append('rubbishPhoto', file);
      }
    }
    window.this = this;
    axios
      .post(`/api/team/${currentTeam}`, formData, {
        headers: {
          'Content-Type': `multipart/form-data`,
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function () {
        // console.log('SUCCESS!!');
        let curUser = store.get('user');
        curUser.myTeams[currentTeam]['teamSlugID'] =
          window.this.state.teamSlugID;
        store.set('user')(curUser);

        window.this.handleClose();
      })
      .catch(function () {
        console.log('FAILURE!!');
      });
  };

  addRubbishType = () => {
    const {
      newSelectRubbishType,
      tabValue,
      rubbishTypes,
      rubbishTeamTypeSchemeID,
      rubbishQrTypesID,
      schemeTypes,
      createNewType,
      newRubbishType,
    } = this.state;

    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const team = store.get('currentTeam');
    let teamType = 'rubbishTeamTypeSchemeID';
    if (tabValue == 1) {
      teamType = 'rubbishQrTypesID';
    }
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    let formRequest = {};
    if (createNewType) {
      formRequest = {
        createNew: true,
        rubbishUserTypesSchemeID: rubbishTeamTypeSchemeID,
        rubbishTypeName: newRubbishType,
      };
    } else {
      formRequest = {
        createNew: false,
        rubbishTypeID: newSelectRubbishType,
      };
    }
    axios
      .post(`/api/team/${team}/add_type/${teamType}`, formRequest)
      .then((teamResponse) => {
        let selectedType = newSelectRubbishType;
        if (formRequest.createNew) {
          let newRubbishTypes = rubbishTypes;
          newRubbishTypes[teamResponse.data.rubbishTypeID] = {
            title: teamResponse.data.rubbishTypeDescription,
            count: 0,
          };
          this.setState({
            rubbishTypes: newRubbishTypes,
          });
          selectedType = teamResponse.data.rubbishTypeID;
          console.log(newRubbishTypes);
        }
        if (teamType == 'rubbishTeamTypeSchemeID') {
          schemeTypes[rubbishTeamTypeSchemeID].rubbishTypeIDs.push(
            selectedType,
          );
        } else {
          schemeTypes[rubbishQrTypesID].rubbishTypeIDs.push(selectedType);
        }
        this.setState({
          schemeTypes: schemeTypes,
          newSelectRubbishType: '',
          newRubbishType: '',
          createNewType: false,
        });
        this.handleClose();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          buttonLoading: false,
        });
      });
  };
  generateApiKey = () => {
    if (
      window.confirm(
        "Are you sure you'd like to generate a new api key for this team?",
      )
    ) {
      const { store } = this.props;
      const { team, rubbishTypes } = this.state;
      const authToken = store.get('user').accessToken;
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      axios
        .post(`/api/team/${team.teamID}/create_api_key`)
        .then((teamResponse) => {
          alert(
            'TeamID: ' +
              teamResponse.data.teamID +
              ' Bearer Token: ' +
              teamResponse.data.token,
          );
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            buttonLoading: false,
          });
        });
    }
  };
  addRubbishTypeToCodes = (rubbishTypeID, rubbishTypeName) => {
    if (
      window.confirm(
        "Are you sure you'd like to add " +
          rubbishTypeName +
          ' to all team codes?',
      )
    ) {
      const { store } = this.props;
      const { team, rubbishTypes } = this.state;
      const authToken = store.get('user').accessToken;
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      axios
        .post(`/api/team/${team.teamID}/add_type_to_codes/${rubbishTypeID}`)
        .then((teamResponse) => {
          rubbishTypes[rubbishTypeID]['count'] = teamResponse.data.count;
          this.setState({ rubbishTypes: rubbishTypes });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            buttonLoading: false,
          });
        });
    }
  };
  removeRubbishTypeFromCodes = (rubbishTypeID, rubbishTypeName) => {
    if (
      window.confirm(
        "Are you sure you'd like to remove " +
          rubbishTypeName +
          ' from all team codes?',
      )
    ) {
      const { store } = this.props;
      const { team, rubbishTypes } = this.state;
      const authToken = store.get('user').accessToken;
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      axios
        .delete(
          `/api/team/${team.teamID}/remove_type_from_codes/${rubbishTypeID}`,
        )
        .then((teamResponse) => {
          rubbishTypes[rubbishTypeID]['count'] = 0;
          this.setState({ rubbishTypes: rubbishTypes });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            buttonLoading: false,
          });
        });
    }
  };
  setTimezone = (timezoneName, timezoneOffset) => {
    this.setState({
      defaultTimezone: timezoneName,
      timezoneOffset: timezoneOffset,
    });
  };
  isloading = (isLoading) => {
    this.setState({ uiLoading: isLoading });
  };
  handleOpen = () => {
    this.setState({ modalOpen: true });
  };
  handleOpenType = () => {
    this.setState({ modalTypeOpen: true });
  };
  handleOpenEditTeam = () => {
    this.setState({ teamEditOpen: true });
  };
  handleClose = () => {
    this.setState({
      modalOpen: false,
      modalTypeOpen: false,
      teamEditOpen: false,
    });
  };
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };
  setToast = (message) => {
    this.setState({ toastOpen: true, modalOpen: false, toastMessage: message });
  };
  editTeamMember = (userID) => {
    return <EditTeamMember userID={userID} />;
  };
  addTeamMember = (message, user = null) => {
    const { team } = this.state;
    team.membersID.push(user.userId);
    if (user.isModerator) {
      team.moderatorsID.push(user.userId);
    }
    team.teamMembers.push({
      userID: user.userId,
      firstName: user.firstName,
      lastName: user.lastName,
    });
    this.setState({
      toastOpen: true,
      modalOpen: false,
      toastMessage: message,
      team: team,
    });
  };
  onSelectType = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  removeTeamMember = (teamMemberID, memberName) => {
    if (
      window.confirm("Are you sure you'd like to remove " + memberName + '?')
    ) {
      const { store } = this.props;
      const { team } = this.state;
      const authToken = store.get('user').accessToken;
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      axios
        .delete(`/api/team/${team.teamID}/remove_member/${teamMemberID}`)
        .then((teamResponse) => {
          team.membersID = team.membersID.filter(
            (item) => item !== teamMemberID,
          );
          team.moderatorsID = team.moderatorsID.filter(
            (item) => item !== teamMemberID,
          );
          team.teamMembers = team.teamMembers.filter(
            (item) => item.userID !== teamMemberID,
          );
          this.setState({ team: team });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            buttonLoading: false,
          });
        });
    }
  };
  removeRubbishType = (rubbishTypeID, rubbishTypeName, teamType) => {
    if (
      window.confirm(
        "Are you sure you'd like to remove " + rubbishTypeName + '?',
      )
    ) {
      const { store } = this.props;
      const { team, rubbishTeamTypeSchemeID, rubbishQrTypesID, schemeTypes } =
        this.state;
      const authToken = store.get('user').accessToken;
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      axios
        .delete(
          `/api/team/${team.teamID}/remove_type/${teamType}/${rubbishTypeID}`,
        )
        .then((teamResponse) => {
          if (teamType == 'rubbishTeamTypeSchemeID') {
            schemeTypes[rubbishTeamTypeSchemeID].rubbishTypeIDs = schemeTypes[
              rubbishTeamTypeSchemeID
            ].rubbishTypeIDs.filter((item) => item !== rubbishTypeID);
          } else {
            schemeTypes[rubbishQrTypesID].rubbishTypeIDs = schemeTypes[
              rubbishQrTypesID
            ].rubbishTypeIDs.filter((item) => item !== rubbishTypeID);
          }
          this.setState({ schemeTypes: schemeTypes });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            buttonLoading: false,
          });
        });
    }
  };
  triggerPhoto = (e) => {
    e.preventDefault();
    document.getElementById('rubbishPhoto').click();
  };
  showPhotoPreview = (e) => {
    const { photoPreview } = this.state;
    const file = document.querySelector('#rubbishPhoto').files[0];
    if (file) {
      var reader = new FileReader();
      window.this = this;
      reader.onload = function (e) {
        let photoPreview = (
          <img className="photo-preview" src={e.target.result} />
        );
        window.this.setState({
          photoPreview: photoPreview,
        });
      };

      reader.readAsDataURL(file); // convert to base64 string
    }
  };

  render() {
    const { classes, store } = this.props;
    const {
      team,
      modalOpen,
      modalTypeOpen,
      toastOpen,
      teamEditOpen,
      vertical,
      horizontal,
      toastMessage,
      tabValue,
    } = this.state;
    let userRole = store.get('user').userRole;

    if (this.state.uiLoading === true) {
      return (
        <div className={classes.root}>
          <Header></Header>

          <main className={classes.content}>
            <div className={classes.toolbar} />
            {this.state.uiLoading && (
              <CircularProgress size={150} className={classes.uiProgess} />
            )}
          </main>
        </div>
      );
    } else {
      let modalStyle = {
        top: `45%`,
        left: `50%`,
        transform: `translate(-45%, -50%)`,
      };
      let footerImagePreview = '';
      if (this.state.team.footerPhotoURL) {
        footerImagePreview = <img src={this.state.team.footerPhotoURL} />;
      }

      let schemaSelector = '';

      if (userRole == 'admin') {
        schemaSelector = (
          <>
            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" className={classes.selectControl}>
                <InputLabel id="rubbishTeamTypeSchemeIDLabel">
                  Generic Scheme
                </InputLabel>
                <Select
                  fullWidth
                  value={this.state.rubbishTeamTypeSchemeID || ''}
                  name="rubbishTeamTypeSchemeID"
                  label="Select issue Type..."
                  id="rubbishTeamTypeSchemeID"
                  onChange={this.onSelectType}
                >
                  <MenuItem key={0} value="">
                    Select issue type...
                  </MenuItem>
                  {Object.keys(this.state.schemeTypes).map((key) => {
                    const s = this.state.schemeTypes[key];
                    return (
                      <MenuItem key={key} value={key}>
                        {s['name']}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" className={classes.selectControl}>
                <InputLabel id="rubbishQrTypesIDLabel">QR Scheme</InputLabel>
                <Select
                  fullWidth
                  value={this.state.rubbishQrTypesID || ''}
                  name="rubbishQrTypesID"
                  label="Select issue Type..."
                  id="rubbishQrTypesID"
                  onChange={this.onSelectType}
                >
                  <MenuItem key={0} value="">
                    Select issue type...
                  </MenuItem>
                  {Object.keys(this.state.schemeTypes).map((key) => {
                    const s = this.state.schemeTypes[key];
                    return (
                      <MenuItem key={key} value={key}>
                        {s['name']}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </>
        );
      }
      let defSchemeTypes =
        this.state.schemeTypes[this.state.rubbishTeamTypeSchemeID];
      if (defSchemeTypes == undefined) {
        defSchemeTypes = { rubbishTypeIDs: [] };
      }
      let qrSchemeTypes = this.state.schemeTypes[this.state.rubbishQrTypesID];
      if (qrSchemeTypes == undefined) {
        qrSchemeTypes = { rubbishTypeIDs: [] };
      }

      let addRubbishType = (
        <>
          <h4>Add Rubbish Type</h4>
          <FormControl variant="outlined" className={classes.selectControl}>
            <InputLabel id="newSelectRubbishTypeLabel">
              Select Rubbish Type...
            </InputLabel>
            <Select
              fullWidth
              value={this.state.newSelectRubbishType || ''}
              name="newSelectRubbishType"
              label="Select Issue Type..."
              id="newSelectRubbishType"
              onChange={this.onSelectType}
            >
              <MenuItem key={0} value="">
                Select Rubbish type...
              </MenuItem>
              {Object.keys(this.state.rubbishTypes).map((key) => {
                const s = this.state.rubbishTypes[key]['title'];
                return (
                  <MenuItem key={key} value={key}>
                    {s}
                  </MenuItem>
                );
              })}
            </Select>
            <a
              href="#"
              onClick={() => {
                this.setState({
                  createNewType: !this.state.createNewType,
                });
              }}
              color="primary"
              className="text-center"
              style={{ marginTop: 10 }}
            >
              - Create New -
            </a>
            <Button
              onClick={this.addRubbishType}
              style={{ marginTop: '15px' }}
              variant="contained"
              color="primary"
            >
              Add
            </Button>
          </FormControl>
        </>
      );

      if (this.state.createNewType) {
        addRubbishType = (
          <>
            <h4>Create New Rubbish Type</h4>
            <FormControl variant="outlined" className={classes.selectControl}>
              <TextField
                fullWidth
                label="Rubbish Type"
                margin="dense"
                name="newRubbishType"
                variant="outlined"
                value={this.state.newRubbishType || ''}
                onChange={this.handleChange}
              />
              <a
                onClick={() => {
                  this.setState({
                    createNewType: !this.state.createNewType,
                  });
                }}
                href="#"
                color="primary"
                className="text-center"
                style={{ marginTop: 10 }}
              >
                - Add Existing -
              </a>
              <Button
                onClick={this.addRubbishType}
                style={{ marginTop: '15px' }}
                variant="contained"
                color="primary"
              >
                Create
              </Button>
            </FormControl>
          </>
        );
      }

      return (
        <div className={classes.root}>
          <Header></Header>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Dialog
              fullWidth
              maxWidth="lg"
              open={teamEditOpen}
              onClose={this.handleClose}
              aria-labelledby="max-width-dialog-title"
            >
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Team Name"
                      margin="dense"
                      name="teamDisplayName"
                      variant="outlined"
                      value={this.state.teamDisplayName || ''}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Team URL"
                      margin="dense"
                      name="teamSlugID"
                      variant="outlined"
                      value={this.state.teamSlugID || ''}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Footer URL"
                      margin="dense"
                      name="footerPhotoLink"
                      variant="outlined"
                      value={this.state.footerPhotoLink || ''}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Newsletter Text"
                      margin="dense"
                      name="newsletterText"
                      variant="outlined"
                      value={
                        this.state.newsletterText ||
                        'Sign up to our newsletter!'
                      }
                      onChange={this.handleChange}
                    />
                  </Grid>
                  {schemaSelector}
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Confirmation Text Message Text"
                      margin="dense"
                      multiline
                      rows={3}
                      name="confirmationTextMessageText"
                      variant="outlined"
                      value={this.state.confirmationTextMessageText || ''}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Confirmation Email Text"
                      margin="dense"
                      multiline
                      rows={3}
                      name="confirmationEmailMessageText"
                      variant="outlined"
                      value={this.state.confirmationEmailMessageText || ''}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Resolution Text Message Text"
                      margin="dense"
                      multiline
                      rows={3}
                      name="resolutionTextMessageText"
                      variant="outlined"
                      value={this.state.resolutionTextMessageText || ''}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Resolution Email Text"
                      margin="dense"
                      multiline
                      rows={3}
                      name="resolutionEmailMessageText"
                      variant="outlined"
                      value={this.state.resolutionEmailMessageText || ''}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Main Title"
                      margin="dense"
                      multiline
                      rows={3}
                      name="pageHeader"
                      variant="outlined"
                      value={this.state.pageHeader || ''}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Main Subtitle"
                      margin="dense"
                      multiline
                      rows={3}
                      name="pageSubHeader"
                      variant="outlined"
                      value={this.state.pageSubHeader || ''}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Success Title"
                      margin="dense"
                      multiline
                      rows={3}
                      name="successTitle"
                      variant="outlined"
                      value={this.state.successTitle || ''}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Success Subtitle"
                      margin="dense"
                      multiline
                      rows={3}
                      name="successSubtitle"
                      variant="outlined"
                      value={this.state.successSubtitle || ''}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Final Title"
                      margin="dense"
                      multiline
                      rows={3}
                      name="finalTitle"
                      variant="outlined"
                      value={this.state.finalTitle || ''}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Final Subtitle"
                      margin="dense"
                      multiline
                      rows={3}
                      name="finalSubtitle"
                      variant="outlined"
                      value={this.state.finalSubtitle || ''}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Select Issue Type Text Override"
                      margin="dense"
                      multiline
                      rows={3}
                      name="selectIssueOverride"
                      variant="outlined"
                      value={
                        this.state.selectIssueOverride || 'Select issue type...'
                      }
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Submit Button Text Override"
                      margin="dense"
                      multiline
                      rows={3}
                      name="submitButtonOverride"
                      variant="outlined"
                      value={this.state.submitButtonOverride || 'Submit'}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Report/Log Initial Issue Text Override"
                      margin="dense"
                      multiline
                      rows={3}
                      name="reportFirstOverride"
                      variant="outlined"
                      value={this.state.reportFirstOverride || ''}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Report/Log Another Issue Text Override (After submitting first report)"
                      margin="dense"
                      multiline
                      rows={3}
                      name="reportAnotherOverride"
                      variant="outlined"
                      value={this.state.reportAnotherOverride || ''}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <SelectTimezoneMaterialUi
                      label="Timezone"
                      margin="dense"
                      fullWidth
                      defaultTimezoneName={
                        this.state.defaultTimezone || undefined
                      }
                      variant="outlined"
                      name="defaultTimezone"
                      helperText="Please select a timezone from the list"
                      onChange={this.setTimezone}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      className={classes.button}
                      fullWidth
                      variant="outlined"
                      size="large"
                      endIcon={<CameraAltIcon />}
                      onClick={this.triggerPhoto}
                    >
                      {this.state.photoPreview}
                    </Button>
                    <input
                      onChange={this.showPhotoPreview}
                      id="rubbishPhoto"
                      name="rubbishPhoto"
                      type="file"
                      accept="image/*"
                    />
                    {footerImagePreview}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.skipNotificationRequest || false}
                          name="skipNotificationRequest"
                          onChange={this.handleCheck}
                          color="primary"
                        />
                      }
                      label="Skip Notification Request"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.enableEmailNotifications || false}
                          name="enableEmailNotifications"
                          onChange={this.handleCheck}
                          color="primary"
                        />
                      }
                      label="Enable Email Notifications"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.enableTextNotifications || false}
                          name="enableTextNotifications"
                          onChange={this.handleCheck}
                          color="primary"
                        />
                      }
                      label="Enable Text Notifications"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.enableAICategorization || false}
                          name="enableAICategorization"
                          onChange={this.handleCheck}
                          color="primary"
                        />
                      }
                      label="Enable AI Categorization"
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={this.generateApiKey}
                >
                  Generate API Key
                </Button>
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={this.handleClose}
                >
                  Close
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={this.updateTeam}
                  disabled={this.state.buttonLoading}
                >
                  Save details
                  {this.state.buttonLoading && (
                    <CircularProgress size={30} className={classes.progess} />
                  )}
                </Button>
              </DialogActions>
            </Dialog>
            <Card className={clsx(classes.root, classes)}>
              <div
                className={classes.gridContainer}
                autoComplete="off"
                noValidate
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Team Name"
                        margin="dense"
                        name="teamDisplayName"
                        variant="outlined"
                        disabled={true}
                        value={this.state.teamDisplayName || ''}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Team URL"
                        margin="dense"
                        name="teamSlugID"
                        variant="outlined"
                        disabled={true}
                        value={this.state.teamSlugID || ''}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Team ID"
                        margin="dense"
                        name="teamID"
                        variant="outlined"
                        disabled={true}
                        value={this.state.team.teamID}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Link
                        style={{ fontSize: 18 }}
                        target="_about"
                        href={'/t/' + this.state.teamSlugID + '?preview=true'}
                      >
                        Preview Mobile Link
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  {this.state.isModerator ? (
                    <Button
                      className={classes.saveRight}
                      color="primary"
                      variant="contained"
                      type="submit"
                      onClick={this.handleOpenEditTeam}
                    >
                      Edit Details
                    </Button>
                  ) : (
                    ''
                  )}
                </CardActions>
              </div>
            </Card>
            <br />
            <Card className={clsx(classes.root, classes)}>
              <form
                className={classes.gridContainer}
                autoComplete="off"
                noValidate
              >
                <Divider />
                <CardHeader
                  title="Team Members"
                  subheader="Manage Team"
                  action={
                    this.state.isModerator ? (
                      <>
                        <ManageInvites />
                        <Tooltip title="Invite Member">
                          <IconButton
                            onClick={this.handleOpen}
                            aria-label="settings"
                            color="primary"
                          >
                            <AddCircleIcon fontSize="large" color="primary" />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      ''
                    )
                  }
                ></CardHeader>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <List>
                        {team.teamMembers.map((key) => {
                          let moderator = '';
                          if (team.moderatorsID.includes(key.userID)) {
                            moderator = (
                              <Badge
                                overlap="rectangular"
                                className={classes.badge}
                                badgeContent={'Moderator'}
                                color="primary"
                              />
                            );
                          }
                          let owner = '';
                          if (team.ownersID.includes(key.userID)) {
                            owner = (
                              <Badge
                                overlap="rectangular"
                                className={classes.badge}
                                badgeContent={'Owner'}
                                color="primary"
                              />
                            );
                          }
                          let secondaryText = (
                            <>
                              {moderator}
                              {owner}
                            </>
                          );
                          return (
                            <ListItem key={key.userID} alignItems="flex-start">
                              <ListItemAvatar>
                                <Avatar
                                  alt={key.firstName + ' ' + key.lastName}
                                  src={key.photoURL}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={key.firstName + ' ' + key.lastName}
                                secondary={secondaryText}
                              />
                              {this.state.isModerator ? (
                                <ListItemSecondaryAction>
                                  <Tooltip title="Email Login Link To User">
                                    <IconButton
                                      onClick={() =>
                                        this.sendSignInLinkToEmail(key.email)
                                      }
                                      aria-label="Send Login Link"
                                    >
                                      <MailIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <EditTeamMember
                                    userID={key.userID}
                                    setToast={() => this.setToast}
                                  />
                                  <Tooltip title="Remove Member">
                                    <IconButton
                                      onClick={() =>
                                        this.removeTeamMember(
                                          key.userID,
                                          key.firstName + ' ' + key.lastName,
                                        )
                                      }
                                      edge="end"
                                      aria-label="delete"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </ListItemSecondaryAction>
                              ) : (
                                ''
                              )}
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions />
              </form>
            </Card>

            <br />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AppBar className={classes.appBar} position="static">
                  <Tabs
                    value={tabValue}
                    onChange={this.handleTabChange}
                    aria-label="simple tabs example"
                  >
                    <Tab label="Mobile Issue Types" {...a11yProps(0)} />
                    <Tab label="QR Code Issue Types" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>

                <TabPanel value={tabValue} index={0}>
                  <Card className={classes.gridContainer}>
                    <CardHeader
                      title="Mobile Issue Types"
                      subheader="These issues can be reported from anywhere."
                      action={
                        this.state.isModerator ? (
                          <Tooltip title="Add New Type">
                            <IconButton
                              onClick={this.handleOpenType}
                              aria-label="settings"
                            >
                              <AddCircleIcon fontSize="large" color="primary" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ''
                        )
                      }
                    ></CardHeader>
                    <CardContent>
                      <Grid container>
                        <Grid item md={12} xs={12}>
                          <List>
                            {defSchemeTypes.rubbishTypeIDs.map((key) => {
                              if (
                                key.length > 0 &&
                                this.state.rubbishTypes[key]
                              ) {
                                return (
                                  <ListItem key={key} alignItems="flex-start">
                                    <ListItemText
                                      primary={
                                        this.state.rubbishTypes[key]['title']
                                      }
                                    />
                                    {this.state.isModerator ? (
                                      <ListItemSecondaryAction>
                                        <Tooltip title="Delete Type">
                                          <IconButton
                                            onClick={() =>
                                              this.removeRubbishType(
                                                key,
                                                this.state.rubbishTypes[key][
                                                  'title'
                                                ],
                                                'rubbishTeamTypeSchemeID',
                                              )
                                            }
                                            edge="end"
                                            aria-label="delete"
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </ListItemSecondaryAction>
                                    ) : (
                                      ''
                                    )}
                                  </ListItem>
                                );
                              }
                            })}
                          </List>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                  <Card className={classes.gridContainer}>
                    <CardHeader
                      title="QR Code Issue Types"
                      subheader="These issues can be attached to QR Codes."
                      action={
                        this.state.isModerator ? (
                          <Tooltip title="Add New Type">
                            <IconButton
                              onClick={this.handleOpenType}
                              aria-label="settings"
                            >
                              <AddCircleIcon fontSize="large" color="primary" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ''
                        )
                      }
                    ></CardHeader>
                    <CardContent>
                      <Grid container>
                        <Grid item md={12} xs={12}>
                          <List>
                            {qrSchemeTypes.rubbishTypeIDs.map((key) => {
                              if (
                                key.length > 0 &&
                                this.state.rubbishTypes[key]
                              ) {
                                return (
                                  <ListItem key={key} alignItems="flex-start">
                                    <ListItemText
                                      primary={
                                        this.state.rubbishTypes[key]['title'] +
                                        ' (' +
                                        this.state.rubbishTypes[key]['count'] +
                                        ')'
                                      }
                                    />
                                    {this.state.isModerator ? (
                                      <ListItemSecondaryAction>
                                        <>
                                          <Tooltip title="Apply to All Team Codes">
                                            <IconButton
                                              onClick={() =>
                                                this.addRubbishTypeToCodes(
                                                  key,
                                                  this.state.rubbishTypes[key][
                                                    'title'
                                                  ],
                                                )
                                              }
                                            >
                                              <PlaylistAddIcon />
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip title="Remove From All Team Codes">
                                            <IconButton
                                              onClick={() =>
                                                this.removeRubbishTypeFromCodes(
                                                  key,
                                                  this.state.rubbishTypes[key][
                                                    'title'
                                                  ],
                                                )
                                              }
                                            >
                                              <BackspaceIcon />
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip title="Delete Type">
                                            <IconButton
                                              onClick={() =>
                                                this.removeRubbishType(
                                                  key,
                                                  this.state.rubbishTypes[key][
                                                    'title'
                                                  ],
                                                  'rubbishQrTypesID',
                                                )
                                              }
                                              edge="end"
                                              aria-label="delete"
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                      </ListItemSecondaryAction>
                                    ) : (
                                      ''
                                    )}
                                  </ListItem>
                                );
                              }
                            })}
                          </List>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </TabPanel>
              </Grid>
            </Grid>

            <Modal
              open={modalTypeOpen}
              onClose={this.handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div style={modalStyle} className={classes.paper}>
                <Grid item md={12} xs={12}>
                  {addRubbishType}
                </Grid>
              </div>
            </Modal>
            <Dialog
              fullWidth
              maxWidth="sm"
              open={modalOpen}
              onClose={this.handleClose}
              aria-labelledby="max-width-dialog-title"
            >
              <DialogContent>
                <AddTeamMember
                  addTeamMember={(message, userId, userName) =>
                    this.addTeamMember(message, userId, userName)
                  }
                />
              </DialogContent>
            </Dialog>
            <Snackbar
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              open={toastOpen}
              onClose={this.handleToastClose}
              message={toastMessage}
              key={'topright'}
            />

            <Footer></Footer>
          </main>
        </div>
      );
    }
  }
}

export default Store.withStore(withStyles(styles)(Team));
