import React, { Component } from 'react';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import config from 'services/config';

import Home from './pages/Home';
import Reports from './pages/Reports';
import Team from './pages/Team';
import Codes from './pages/Codes';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Scan from './pages/Scan';
import Dashboard from './pages/Dashboard';

import { auth } from './services/firebase';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
// import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import axios from './helpers/axios';
import Store from './services/Store';
// import CircularProgress from '@material-ui/core/CircularProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { ConfirmProvider } from 'material-ui-confirm';

LicenseInfo.setLicenseKey(
  '389870a4ec9d9b2bc768f1b075fc365dT1JERVI6MzA2MDEsRVhQSVJZPTE2NjUyOTU0MTcwMDAsS0VZVkVSU0lPTj0x',
);

import './styles.css';

const theme = createTheme({
  palette: {
    primary: {
      light: '#33c9dc',
      main: config.primaryColor || '#F10382',
      dark: '#ccc',
      contrastText: '#fff',
    },
    secondary: {
      main: config.secondaryColor || '#f50057',
    },
  },
});

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true ? (
          <div className="admin">
            <Component {...props} />
          </div>
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, authenticated = null, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === false || authenticated === null ? (
          <div className="admin">
            <Component {...props} />
          </div>
        ) : (
          <Redirect to="/account" />
        )
      }
    />
  );
}

function ScanRoute({ component: Component, authenticated = null, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        props.authenticated = authenticated;
        return <Component authenticated={authenticated} {...props} />;
      }}
    />
  );
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      loading: true,
    };
  }

  componentDidMount() {
    // const [state, dispatch] = this.context;
    const { store } = this.props;

    // auth().onAuthStateChanged((user) => {
    //   if (user) {
    //     console.log('user not set, auth changed');
    //     user.getIdToken().then((accessToken) => {
    //       axios.defaults.headers.common = {
    //         Authorization: `Bearer ${accessToken}`,
    //       };
    //       axios
    //         .get('/api/user')
    //         .then((response) => {
    //           localStorage.setItem('token', accessToken);
    //           console.log('user not set, user set');

    //           store.set('user')({
    //             accessToken: accessToken,
    //             authenticated: true,
    //             firstName: response.data.userCredentials.firstName,
    //             lastName: response.data.userCredentials.lastName,
    //             userRole: response.data.userCredentials.userRole,
    //             myTeams: response.data.teams,
    //             photoURL: response.data.userCredentials.photoURL,
    //             email: user.email,
    //             moderator: response.data.moderator,
    //           });
    //           let currentTeam = store.get('currentTeam');
    //           // console.log(Object.keys(response.data.teams)[0]);
    //           if (
    //             (!currentTeam || currentTeam === '0') &&
    //             Object.keys(response.data.teams)[0]
    //           ) {
    //             store.set('currentTeam')(Object.keys(response.data.teams)[0]);
    //           }
    //           this.setState({ loading: false, authenticated: true });
    //         })
    //         .catch((error) => {
    //           window.localStorage.clear();
    //           this.setState({
    //             authenticated: false,
    //             loading: false,
    //           });
    //         });
    //     });
    //   } else {
    //     window.localStorage.clear();
    //     this.setState({
    //       authenticated: false,
    //       loading: false,
    //     });
    //   }
    // });
    auth().onIdTokenChanged((user) => {
      if (user) {
        // console.log('user not set, token changed');
        console.log('user:', user)

        user.getIdToken().then((accessToken) => {
          axios.defaults.headers.common = {
            Authorization: `Bearer ${accessToken}`,
          };
          axios
            .get('/api/user')
            .then((response) => {
              localStorage.setItem('token', accessToken);
              store.set('user')({
                accessToken: accessToken,
                authenticated: true,
                firstName: response.data.userCredentials.firstName,
                lastName: response.data.userCredentials.lastName,
                userRole: response.data.userCredentials.userRole,
                myTeams: response.data.teams,
                photoURL: response.data.userCredentials.photoURL,
                email: user.email,
                moderator: response.data.moderator,
              });
              let currentTeam = store.get('currentTeam');
              // console.log(Object.keys(response.data.teams)[0]);
              if (
                (!currentTeam || currentTeam === '0') &&
                Object.keys(response.data.teams)[0]
              ) {
                store.set('currentTeam')(Object.keys(response.data.teams)[0]);
              } else {
                store.set('currentTeam')(currentTeam);
              }

              this.setState({ loading: false, authenticated: true });
            })
            .catch((error) => {
              window.localStorage.clear();
              this.setState({
                authenticated: false,
                loading: false,
              });
            });
        });
      } else {
        window.localStorage.clear();
        this.setState({
          authenticated: false,
          loading: false,
        });
      }
    });
  }

  render() {
    // const user = useSelector((state) => state.user);
    return this.state.loading === true ? (
      <div>
        <main>
          <div />
          {this.state.loading && (
            <CircularProgress
              size={150}
              style={{
                position: 'fixed',
                zIndex: '1000',
                left: '50%',
                top: '35%',
                marginLeft: -75,
                color: theme.palette.primary.main,
              }}
            />
          )}
        </main>
      </div>
    ) : (
      <MuiThemeProvider theme={theme}>
        <ConfirmProvider>
          <BrowserRouter>
            <Switch>
              <PrivateRoute
                exact
                path="/account"
                authenticated={this.state.authenticated}
                component={Home}
              />
              <PrivateRoute
                exact
                path="/reports"
                authenticated={this.state.authenticated}
                component={Reports}
              />
              <PrivateRoute
                exact
                path="/team"
                authenticated={this.state.authenticated}
                component={Team}
              />
              <PrivateRoute
                exact
                path="/qrcodes"
                authenticated={this.state.authenticated}
                component={Codes}
              />
              <PrivateRoute
                exact
                path="/dashboard"
                authenticated={this.state.authenticated}
                component={Dashboard}
              />
              <PublicRoute
                path="/signup"
                authenticated={this.state.authenticated}
                component={Signup}
              />
              <PublicRoute
                path="/login"
                authenticated={this.state.authenticated}
                component={Login}
              />
              <ScanRoute
                path="/c/:qrCodeID"
                component={Scan}
                authenticated={this.state.authenticated}
              />
              <ScanRoute
                path="/s/:qrCodeSlug"
                component={Scan}
                authenticated={this.state.authenticated}
              />
              <ScanRoute
                path="/t/:teamSlug"
                component={Scan}
                authenticated={this.state.authenticated}
              />
              <PrivateRoute
                exact
                path="/"
                authenticated={this.state.authenticated}
              >
                <Redirect to="/account" />
              </PrivateRoute>
            </Switch>
          </BrowserRouter>
        </ConfirmProvider>
      </MuiThemeProvider>
    );
  }
}

export default Store.withStore(App);
