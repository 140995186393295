import React, { Component } from 'react';
import { Button, TextField, DialogActions } from '@material-ui/core';
import Store from '../../services/Store';
import axios from '../../helpers/axios';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Alert from '@mui/material/Alert';

class AddTeamMember extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newAccount: false,
      newMemberEmail: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      password: '',
      vertical: 'top',
      horizontal: 'right',
      regError: null,
      isModerator: false,
      sendLoginLink: true,
      inviteSent: false,
    };
  }
  handleToastClose = () => {
    this.setState({ toastOpen: false });
  };
  handleChange = (event) => {
    // console.log(event.target.name);
    if (event.target.name == 'newMemberEmail') {
      event.target.value = event.target.value.replace(/\s/g, '');
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleCheck = (event) => {
    if (event.target.checked) {
      this.setState({ [event.target.name]: true });
    } else {
      this.setState({ [event.target.name]: false });
    }
  };
  formatToPhone = (event) => {
    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      target.value = `(${zip}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      target.value = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      target.value = `(${zip}`;
    }
    this.setState({ [event.target.name]: event.target.value });
  };
  addTeamMember = () => {
    if (this.state.newMemberEmail.length > 0) {
      const { store } = this.props;
      const { newAccount } = this.state;
      const authToken = store.get('user').accessToken;
      const team = store.get('currentTeam');
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      let formRequest = {
        newMemberEmail: this.state.newMemberEmail,
        isModerator: this.state.isModerator,
      };

      axios
        .post(`/api/team/${team}/add_member`, formRequest)
        .then((teamResponse) => {
          if (teamResponse.data.userId) {
            // console.log([teamResponse.data.message, teamResponse.data.userId, teamResponse.data.firstName, teamResponse.data.lastName, teamResponse.data.isModerator]);
            this.props.addTeamMember(teamResponse.data.message, {
              userId: teamResponse.data.userId,
              firstName: teamResponse.data.firstName,
              lastName: teamResponse.data.lastName,
              isModerator: teamResponse.data.isModerator,
            });
            this.setState({ regError: null });
          } else {
            this.setState({
              inviteSent: true,
              regError: teamResponse.data.message,
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            let displayErrors = '';
            Object.keys(error.response.data).forEach((key) => {
              displayErrors += error.response.data[key] + ' ';
            });
            this.setState({
              regError: displayErrors,
            });
          }

          this.setState({
            buttonLoading: false,
          });
        });
    }
  };

  render() {
    return (
      <>
        <h2 id="simple-modal-title">Invite Member</h2>
        <FormControl fullWidth component="fieldset">
          {!this.state.inviteSent ? (
            <>
              <FormGroup aria-label="position" row>
                <TextField
                  fullWidth
                  label="Email Address"
                  margin="dense"
                  name="newMemberEmail"
                  variant="outlined"
                  value={this.state.newMemberEmail}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.isModerator}
                      name="isModerator"
                      onChange={this.handleCheck}
                      color="primary"
                    />
                  }
                  label="Make a Moderator?"
                  labelPlacement="end"
                />
              </FormGroup>
            </>
          ) : (
            ''
          )}
          {this.state.regError && (
            <Alert icon={false} variant="outlined" severity="error">
              {this.state.regError || ''}
            </Alert>
          )}
          {!this.state.inviteSent ? (
            <DialogActions>
              <Button
                onClick={this.addTeamMember}
                style={{ marginTop: '15px' }}
                variant="contained"
                color="primary"
              >
                Add
              </Button>
            </DialogActions>
          ) : (
            ''
          )}
        </FormControl>
      </>
    );
  }
}

export default Store.withStore(AddTeamMember);
